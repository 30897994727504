@font-face {
font-family: 'V_INTER';
src: url(https://marketplace-web-assets.vinted.com/_next/static/media/ab46fb435d755552-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: 'V_INTER';
src: url(https://marketplace-web-assets.vinted.com/_next/static/media/82416101390aeef4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}@font-face {font-family: 'V_INTER Fallback';src: local("Arial");ascent-override: 91.84%;descent-override: 22.87%;line-gap-override: 0.00%;size-adjust: 105.48%
}.__className_9a5b19 {font-family: 'V_INTER', 'V_INTER Fallback'
}

